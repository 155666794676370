<template>
  <div class="biModules">
    <div v-if="hasPermission">
      <h1 class="fl">招商可视看板</h1>
      <div class="w-input-sty">
        <el-select
          popper-class="w-block-select-down"
          class="fr"
          v-model="filtroProject"
          size="small"
          placeholder="请选择"
          @change="handleData"
        >
          <el-option
            v-for="item in projectOptions"
            :key="item.id"
            :label="item.projectName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="clearfix"></div>
      <div class="commonBox fl box0 boxHeight0">
        <h2 class="w-title-gradients">招商整体数据总览</h2>
        <div class="wrap0">
          <ul class="thirdUl botBorderDashed">
            <el-tooltip
              class="item"
              effect="dark"
              content="招商完成率：已签订正式合同的铺位建筑面积（去重）/所有铺位建筑面积"
              placement="bottom"
            >
              <li>
                <h3 class="commonText">
                  <span>招商完成率</span>
                </h3>
                <span class="numPanel mt15">{{entiretyData.investmentCompletionRate | keepTwoDecimal}}</span>
              </li>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="未签约面积：未签订正式合同的铺位建筑面积总和"
              placement="bottom"
            >
              <li>
                <p class="commonText">
                  <span>未签约面积（㎡）</span>
                </p>
                <p class="commonNum">{{entiretyData.vacantArea | processData}}</p>
              </li>
            </el-tooltip>
            <!-- content="未签约铺位：铺位状态为「空置」的铺位数量" -->
            <el-tooltip
              class="item"
              effect="dark"
              content="未签约铺位：未签订正式合同的铺位数"
              placement="bottom"
            >
              <li class="fr mt29">
                <p class="commonText">
                  <span>未签约铺位</span>
                </p>
                <p class="commonNum">{{entiretyData.vacantBerth | isShowWhipptree}}</p>
              </li>
            </el-tooltip>
          </ul>
          <div class="clearfix"></div>
          <ul class="halfUl">
            <el-tooltip
              class="item"
              effect="dark"
              content="已签约品牌数量：当前项目已签订正式合同铺位的品牌数量"
              placement="bottom"
            >
              <li>
                <p class="commonText">
                  <span>已签约品牌数量</span>
                </p>
                <p class="commonNum">{{entiretyData.numberOfSignedBrands | isShowWhipptree}}</p>
              </li>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="确认无意向/搁置品牌：本项目已搁置、无意向的品牌数量和"
              placement="bottom"
            >
              <li>
                <p class="commonText">
                  <span>确认无意向/搁置数量</span>
                </p>
                <p class="commonNum">{{entiretyData.onTheNumber | isShowWhipptree}}</p>
              </li>
            </el-tooltip>
          </ul>
          <ul class="halfUl">
            <li>
              <p class="commonText">
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="已签约年度租金：根据当前已签约品牌的已出账及未出账租金总额计算本年度的租金总额"
                  placement="bottom"
                >
                  <span>已签约年度租金（元）</span>
                </el-tooltip>
              </p>
              <p
                class="commonNum"
                :title="entiretyData.annualRentHasBeenContracted | processData">
                {{entiretyData.annualRentHasBeenContracted | processData}}
                <!-- <el-tooltip
                  class="item"
                  effect="dark"
                  content="完成率：已签约年度租金/目标年度租金"
                  placement="bottom"
                >
                  <span class="fs10">完成率：{{entiretyData.percentageComplete | isShowWhipptree}}%</span>
                </el-tooltip> -->
              </p>
            </li>
              <li>
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="目标年度租金：根据所有铺位的本年度租金保底价计算租金目标总额"
                  placement="bottom"
                >
                  <p class="commonText">
                    <span>目标年度租金收益（元）</span>
                  </p>
                </el-tooltip>
                <p
                  class="commonNum"
                  :title="entiretyData.targetAnnualRent | processData"
                >{{entiretyData.targetAnnualRent | processData}}</p>
              </li>
          </ul>
        </div>
      </div>
      <div class="commonBox fl box1 boxHeight0">
        <h2 class="w-title-gradients fl">招商进度对比</h2>
        <div class="fr tbsCommon">
          <span
            :class="{'activeSpan': filtroContrast===0}"
            @click="getDataProgress(0, undefined)"
          >业态对比</span>
          <span
            :class="{'activeSpan': filtroContrast===1}"
            @click="getDataProgress(1, undefined)"
          >楼层对比</span>
        </div>
        <div class="clearfix"></div>
        <div class="ta-c tbsSpan">
          <span
            :class="{'activeSpan': filtroBerth===0}"
            @click="getDataProgress(undefined, 0)"
          >铺位面积</span>
          <span
            :class="{'activeSpan': filtroBerth===1}"
            @click="getDataProgress(undefined, 1)"
          >铺位数量</span>
        </div>
        <div
          v-if="hasDataChartsBarProgress"
          id="chartsBarProgress"
          class="chartsBox0"
        ></div>
        <div
          class="details-no-data mt80"
          v-show="!hasDataChartsBarProgress"
        >
          <img
            src="@/assets/images/public/not-plandata.png"
            alt=""
          >
          <p>抱歉，暂无数据</p>
        </div>
      </div>
      <div class="clearfix"></div>
      <div class="commonBox fl box0">
        <h2 class="w-title-gradients">当前招商品牌预测</h2>
        <div class="wrap1">
          <ul class="thirdUl1">
            <el-tooltip
              class="item"
              effect="dark"
              content="招商转化率：当前项目「已签约」品牌数/招商任务总品牌数x100%"
              placement="bottom"
            >
              <li>
                <span class="commonText">招商转化率 </span>
                <span class="commonNum">{{predictionData.aiConversionRate || '--'}}<span>%</span></span>
              </li>
            </el-tooltip>
            <!-- <el-tooltip
              class="item"
              effect="dark"
              content="预计签约数量：当前项目「已确认」的意向合同数"
              placement="bottom"
            >
              <li>
                <span class="commonText">预计签约数量 </span>
                <span class="commonNum">{{predictionData.exceptSignedNums || '--'}}</span>
              </li>
            </el-tooltip> -->
            <el-tooltip
              class="item"
              effect="dark"
              content="预计新增租金：当前项目已确认意向合同预计贡献月租金"
              placement="bottom"
            >
              <li>
                <span class="commonText">预计新增租金（元）</span>
                <span
                  class="commonNum"
                  :title="predictionData.exceptNewRent | processData"
                >{{predictionData.exceptNewRent | processData}}</span>
              </li>
            </el-tooltip>
          </ul>
          <div
            v-if="hasDataChartsPiePrediction"
            id="chartsPieBrandPrediction"
            class="chartsBox1"
          ></div>
          <div
            class="details-no-data"
            v-show="!hasDataChartsPiePrediction"
          >
            <img
              src="@/assets/images/public/not-plandata.png"
              alt=""
            >
            <p>抱歉，暂无数据</p>
          </div>
        </div>
      </div>
      <div class="commonBox fl box2">
        <h2 class="w-title-gradients fl">年度签约租金与租决对比</h2>
        <div class="fr tbsCommon">
          <span
            :class="{'activeSpan': filtroRent===0}"
            @click="getDataRent(0)"
          >全部楼层</span>
          <span
            :class="{'activeSpan': filtroRent===1}"
            @click="getDataRent(1)"
          >全部业态</span>
        </div>
        <div class="clearfix"></div>
        <div
          v-if="hasDataChartsBarRent"
          id="chartsBarRent"
          class="chartsBox"
        ></div>
        <div
          class="details-no-data"
          v-show="!hasDataChartsBarRent"
        >
          <br>
          <img
            src="@/assets/images/public/not-plandata.png"
            alt=""
          >
          <p>抱歉，暂无数据</p>
        </div>
      </div>
      <div class="commonBox fl box3">
        <h2 class="w-title-gradients fl">签约品牌分析</h2>
        <div class="fr tbsCommon">
          <span
            :class="{'activeSpan': filtroSignedBrand===0}"
            @click="getDataPieSignedBrand(0)"
          >业态分布</span>
          <span
            :class="{'activeSpan': filtroSignedBrand===1}"
            @click="getDataPieSignedBrand(1)"
          >品牌分级</span>
        </div>
        <div class="clearfix"></div>
        <div
          v-if="hasDataPieSignedBrand"
          id="chartsPieSignedBrand"
          class="chartsBox1"
        ></div>
        <div
          class="details-no-data"
          v-show="!hasDataPieSignedBrand"
        >
          <br>
          <img
            src="@/assets/images/public/not-plandata.png"
            alt=""
          >
          <p>抱歉，暂无数据</p>
        </div>
      </div>
      <div class="clearfix"></div>
      <div class="commonBox fl box0">
        <h2 class="w-title-gradients fl">招商漏斗转化</h2>
        <div class="fr commonSelect w-input-sty">
          <el-select
            size="mini"
            popper-class="w-block-select-down"
            :style="'width:' + selectWidthList[filtroFunnelMember.length] +'px'"
            v-model="filtroFunnelMember"
            placeholder="请选择"
            @change="getDataFunnel"
          >
            <el-option
              v-for="item in filtroFunnelMemberOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-select
            popper-class="w-block-select-down"
            v-if="filtroFunnelformatOptions.length>0"
            size="mini"
            :title="filtroFunnelformat"
            :style="'width:' + selectWidthList[filtroFunnelformat.length] +'px'"
            v-model="filtroFunnelformat"
            placeholder="请选择"
            @change="getDataFunnel"
          >
            <el-option
              v-for="item in filtroFunnelformatOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="clearfix"></div>
        <div
          v-if="hasDataChartsFunnel"
          id="chartsFunnel"
          class="chartsBox mt10"
        ></div>
        <div
          class="details-no-data"
          v-show="!hasDataChartsFunnel"
        >
          <br>
          <img
            src="@/assets/images/public/not-plandata.png"
            alt=""
          >
          <p>抱歉，暂无数据</p>
        </div>
      </div>
      <div class="commonBox fl box2">
        <h2 class="w-title-gradients fl">招商状态数量统计</h2>
        <div class="fr commonSelect w-input-sty">
          <el-select
            popper-class="w-block-select-down"
            :style="'width:' + selectWidthList[filtroFunnelMemberNum.length] +'px'"
            size="mini"
            v-model="filtroFunnelMemberNum"
            placeholder="请选择"
            @change="getDataBarState"
          >
            <el-option
              v-for="item in filtroFunnelMemberOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-select
            popper-class="w-block-select-down"
            :style="'width:' + selectWidthList[filtroStateformat.length] +'px'"
            v-if="filtroFunnelformatOptions.length>0"
            size="mini"
            v-model="filtroStateformat"
            placeholder="请选择"
            @change="getDataBarState"
          >
            <el-option
              v-for="item in filtroFunnelformatOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="clearfix"></div>
        <div
          v-if="hasDataChartsBarState"
          id="chartsBarState"
          class="chartsBox mt10"
        ></div>
        <div
          class="details-no-data"
          v-show="!hasDataChartsBarState"
        >
          <br>
          <img
            src="@/assets/images/public/not-plandata.png"
            alt=""
          >
          <p>抱歉，暂无数据</p>
        </div>
      </div>
      <div class="commonBox fl box3">
        <h2 class="w-title-gradients fl">招商成员对比</h2>
        <div class="fr tbsCommon w-input-sty">
          <el-select
            popper-class="w-block-select-down"
            class="fr"
            size="small"
            placeholder="请选择"
            v-model="memberSelectValue"
            @change="getDataPieMember(memberSelectValue)"
          >
            <el-option
              v-for="item in memberSelectList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="clearfix"></div>
        <div
          v-if="hasDataChartsPieMember"
          id="chartsPieMember"
          class="chartsBox1"
        >
        </div>
        <div
          class="details-no-data"
          v-show="!hasDataChartsPieMember"
        >
          <br>
          <img
            src="@/assets/images/public/not-plandata.png"
            alt=""
          >
          <p>抱歉，暂无数据</p>
        </div>
      </div>
      <div class="clearfix"></div>
    </div>
    <div
      class="simpleInfoPanel"
      v-else
    >
      <div class="jurisdiction-wrap">
        <img
          src="~@/assets/images/Jurisdiction.png"
          alt=""
        >
        <p>暂无权限访问此页，如需开通权限，</p>
        <p>请联系管理员开通</p>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import { toThousands } from '@/utils'
import { mapGetters } from 'vuex'
import echarts from 'echarts'
export default {
  data () {
    return {
      projectOptions: [],
      filtroProject: '',
      entiretyData: {},
      chartsBarProgress: null,
      filtroBerth: 0,
      filtroContrast: 0,
      filtroRent: 0,
      filtroSignedBrand: 0,
      filtroMember: 0,
      chartsPieBrandPrediction: null,
      predictionData: {},
      chartsBarRent: null,
      chartsPieSignedBrand: null,
      chartsPieMember: null,
      filtroFunnelMember: '',
      filtroFunnelMemberNum: '',
      filtroFunnelMemberOptions: [],
      filtroFunnelformat: '',
      filtroStateformat: '',
      filtroFunnelformatNum: '全部业态',
      filtroFunnelformatOptions: [],
      chartsBarState: null,
      chartsFunnel: null,
      hasDataChartsPiePrediction: true,
      hasDataChartsBarRent: true,
      hasDataChartsBarProgress: true,
      hasDataChartsFunnel: true,
      hasDataPieSignedBrand: true,
      hasDataChartsBarState: true,
      hasDataChartsPieMember: true,
      hasPermission: true,
      userAuthorities: [],
      reportCenterList: [],
      goLinkArr: [],
      loading: false,
      selectWidthList: [90, 50, 70, 80, 90, 105, 125, 155, 157],
      memberSelectValue: 0,
      memberSelectList: [
        {
          label: '签约品牌',
          value: 0
        },
        {
          label: '在跟进品牌',
          value: 1
        },
        {
          label: '跟进超时品牌',
          value: 2
        }
      ]
    }
  },
  mounted () {
    this.userAuthorities = this.user.confIds.filter(item => {
      return item.id === 133
    })
    if (this.userAuthorities.length) {
      this.hasPermission = true
      this.getDataProjList() // 先获取项目id，然后再请求其他接口
      this.getDataFormat()
      this.getReportCenterList() // 获取图表链接列表
    } else {
      this.hasPermission = false
    }
  },
  filters: {
    keepTwoDecimal: (num) => {
      var result = parseFloat(num)
      if (isNaN(result)) {
        return 0
      } else {
        result = Math.round(num * 100) / 100
        return result
      }
    },
    isShowWhipptree: (val) => {
      let res = ''
      if (val || val === 0) {
        if (Number(val) > 9999999999999) {
          res = val.slice(0, 10) + '...'
        } else {
          res = val
        }
      } else {
        res = '--'
      }
      return res
    },
    processData: (val) => {
      let res = ''
      if (val || val === 0) {
        if (Number(val) > 9999999999999) {
          res = toThousands(val).slice(0, 10) + '...'
        } else {
          res = toThousands(val)
        }
      } else {
        res = '--'
      }
      return res
    }
  },
  computed: {
    ...mapGetters({
      user: 'user'
    }),
    url () {
      let temUrl = ''
      if (process.env.NODE_ENV === 'development') {
        temUrl = 'http://' + document.domain + ':8080'
      }
      return temUrl
    }
  },
  methods: {
    getReportCenterList () {
      const params = {
      }
      this.axios.post(api.getBBList, params).then(res => {
        if (res.data.code === 0 && res.data.data) {
          const resData = res.data.data
          this.reportCenterList = [...resData.filter(item => {
            return item.name === '招商分析'
          })].shift()
          if (this.reportCenterList && this.reportCenterList.dicVOS && this.reportCenterList.dicVOS.length) {
            this.goLinkArr[0] = [...this.reportCenterList.dicVOS.filter(item => {
              return item.name === '招商进度分析'
            })].shift()
            this.goLinkArr[1] = [...this.reportCenterList.dicVOS.filter(item => {
              return item.name === '跟进品牌分析'
            })].shift()
            this.goLinkArr[2] = [...this.reportCenterList.dicVOS.filter(item => {
              return item.name === '招商租决分析'
            })].shift()
            this.goLinkArr[3] = [...this.reportCenterList.dicVOS.filter(item => {
              return item.name === '签约品牌分析'
            })].shift()
            this.goLinkArr[4] = [...this.reportCenterList.dicVOS.filter(item => {
              return item.name === '招商转化分析'
            })].shift()
            this.goLinkArr[5] = [...this.reportCenterList.dicVOS.filter(item => {
              return item.name === '跟进品牌分析'
            })].shift()
            this.goLinkArr[6] = [...this.reportCenterList.dicVOS.filter(item => {
              return item.name === '团队招商分析'
            })].shift()
          }
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    handleData () {
      this.updateProjectIdFunc()
      this.getDataFollower() // 获取人员select option
      this.getDataPrediction() // 3当前招商品牌预测
      this.getDataRent()
      this.getDataFunnel()
      this.getDataPieSignedBrand()
      this.getDataBarState()
      this.getDataPieMember()
      this.getDataProgress()
      this.getDataEntirety()
    },
    goLink (num) {
      window.open(this.url + '/#/reportCenter?url=' + this.goLinkArr[num].url + '&cid=' + this.goLinkArr[num].id)
    },
    getDataFollower () {
      const params = {
        isShow: '1', // 1不显示已删除用户
        projectId: this.filtroProject
      }
      this.axios.post(api.getFollowList, params).then(res => {
        if (res.data.code === 0 && res.data.data) {
          const resData = res.data.data
          this.filtroFunnelMemberOptions = resData.map(item => {
            const tempObj = {
              label: item.userName,
              value: item.userId
            }
            return tempObj
          })
          this.filtroFunnelMemberOptions.unshift({
            label: '全部人员',
            value: ''
          })
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    getDataFormat () {
      const params = {
      }
      this.axios.post(api.getListIndustry1, params).then(res => {
        if (res.data.code === 0 && res.data.data) {
          const resData = res.data.data
          this.filtroFunnelformatOptions = resData.map(item => {
            return item
          })
          this.filtroFunnelformatOptions.unshift({
            name: '全部业态',
            id: ''
          })
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    getDataProjList () {
      this.loading = true
      this.axios.post(api.getBerthProjectList, {
      }).then(res => {
        if (res.data.code === 0) {
          this.projectOptions = res.data.data
          this.getProjectIdFunc().then((res) => {
            this.filtroProject = res.data.data.projectId
            this.handleData() // 拿到第一个项目id再调用接口
          })
        }
        this.loading = false
      }, rej => {
        console.log(rej)
        this.loading = false
      }).catch(err => {
        console.log(err)
        this.loading = false
      })
    },
    updateProjectIdFunc () {
      const params = {
        projectId: this.filtroProject
      }
      this.axios.post(api.updateProjectId, params)
        .then((res) => {
        })
    },
    getProjectIdFunc () {
      return new Promise((resolve, reject) => {
        this.axios.get(api.getProjectId)
          .then((res) => {
            if (res.status === 200) {
              resolve(res)
            } else {
              reject(new Error(''))
            }
          })
      })
    },
    getDataEntirety (val) {
      const params = {
        projectId: this.filtroProject
      }
      this.axios.post(api.getAnnualRentHasBeenContracted, params).then(res => {
        if (res.data.code === 0 && res.data.data) {
          const resData = res.data.data
          this.entiretyData = resData
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    getDataProgress (param0, param1) {
      if (param0 !== undefined) {
        this.filtroContrast = param0
      }
      if (param1 !== undefined) {
        this.filtroBerth = param1
      }
      const params = {
        industryOrFloor: this.filtroContrast,
        areaOrNum: this.filtroBerth,
        projectId: this.filtroProject
      }
      const that = this
      this.axios.post(api.getAiProgressAnalysis, params).then(res => {
        if (res.data.code === 0 && res.data.data) {
          const resData = res.data.data
          if (resData.chartItem.labels.length !== 0) {
            this.hasDataChartsBarProgress = true
            const legendData = []
            const seriesData = resData.chartItem.series.map(item => {
              const tempObj = {
                name: item.name,
                type: 'bar',
                barWidth: '18',
                barGap: '130%',
                data: item.data
              }
              legendData.push(item.name)
              return tempObj
            })
            this.$nextTick(() => {
              this.chartsBarProgress = echarts.init(document.getElementById('chartsBarProgress'))
              this.chartsBarProgress.setOption(this.getOptionChartsBar())
              window.addEventListener('resize', () => {
                this.chartsBarProgress.resize()
              })
              this.chartsBarProgress.on('click', function (param) {
                that.goLink(0)
              })
              this.chartsBarProgress.setOption(
                {
                  legend: {
                    data: legendData
                  },
                  tooltip: {
                    formatter: function (params) {
                      let res = ''
                      const val0 = Number(params[0].data)
                      const val1 = Number(params[1].data)
                      if (val1) {
                        res = (val0 / val1) * 100
                      }
                      let tempStr = '<span style="color:#FFA134;font-weight:bold;font-size:15px">' + params[0].name + '</span>'
                      if (that.filtroBerth) {
                        tempStr += '<br>已完成招商：' + val0
                        tempStr += '<br>规划招商：' + val1
                      } else {
                        tempStr += '<br>已完成招商：' + val0.toFixed(2) + '㎡'
                        tempStr += '<br>规划招商：' + val1.toFixed(2) + '㎡'
                      }
                      if (typeof res === 'number') {
                        tempStr += '<br>招商进度：' + res.toFixed(2) + '%'
                      } else {
                        tempStr += '<br>招商进度：-%'
                      }
                      return tempStr
                    }
                    // 招商进度：${当前项目已签约业态的铺位面积}/${铺位规划业态建筑面积}x100%
                  },
                  yAxis: [
                    {
                      name: this.filtroBerth ? '数量/个' : '面积/㎡',
                      axisLabel: {
                        formatter: '{value}'
                      }
                    }
                  ],
                  xAxis: [
                    {
                      data: resData.chartItem.labels
                    }
                  ],
                  series: seriesData
                }
              )
            })
          } else {
            this.hasDataChartsBarProgress = false
            if (this.chartsBarProgress) {
              this.chartsBarProgress.dispose()
              this.chartsBarProgress = null
            }
          }
        }
      }, rej => {
        this.hasDataChartsBarProgress = false
        console.log(rej)
      }).catch(err => {
        this.hasDataChartsBarProgress = false
        console.log(err)
      })
    },
    getDataPrediction () {
      const params = {
        projectId: this.filtroProject
      }
      const that = this
      this.axios.post(api.getAiBrandForecast, params).then(res => {
        if (res.data.code === 0 && res.data.data) {
          const resData = res.data.data
          if (resData.chartItem.labels.length > 0) {
            this.hasDataChartsPiePrediction = true
            this.predictionData = resData
            const seriesData = resData.chartItem.labels.map((item, index) => {
              const tempObj = {
                name: item,
                value: resData.chartItem.values[index]
              }
              return tempObj
            })
            this.$nextTick(() => {
              this.chartsPieBrandPrediction = echarts.init(document.getElementById('chartsPieBrandPrediction'))
              this.chartsPieBrandPrediction.setOption(this.getOptionChartsPie())
              this.chartsPieBrandPrediction.setOption({
                title: {
                  text: resData.followingBrandNums,
                  left: resData.signedBrandNums < 10 ? '25.5%' : '23.1%'
                },
                graphic: {
                  left: '22.2%',
                  style: {
                    text: '在跟进品牌',
                    fill: '#fff',
                    fontSize: 12
                  }
                },
                legend: {
                  data: resData.chartItem.labels
                },
                series: [
                  {
                    data: seriesData
                  }
                ]
              })
              window.addEventListener('resize', () => {
                this.chartsPieBrandPrediction.resize()
              })
              this.chartsPieBrandPrediction.on('click', function (param) {
                that.goLink(1)
              })
            })
          } else {
            this.hasDataChartsPiePrediction = false
            if (this.chartsPieBrandPrediction) {
              this.chartsPieBrandPrediction.dispose()
              this.chartsPieBrandPrediction = null
            }
          }
        }
      }, rej => {
        this.hasDataChartsPiePrediction = false
        console.log(rej)
      }).catch(err => {
        this.hasDataChartsPiePrediction = false
        console.log(err)
      })
    },
    getDataPieSignedBrand (val) {
      if (val !== undefined) {
        this.filtroSignedBrand = val
      }
      const params = {
        industryOrLevel: this.filtroSignedBrand,
        projectId: this.filtroProject
      }
      this.axios.post(api.getSignedBrandAnalysis, params).then(res => {
        if (res.data.code === 0 && res.data.data) {
          const resData = res.data.data
          const that = this
          const seriesData = resData.chartItem.labels.map((item, index) => {
            const tempObj = {
              name: item,
              value: resData.chartItem.values[index]
            }
            return tempObj
          })
          if (seriesData.length) {
            this.hasDataPieSignedBrand = true
            this.$nextTick(() => {
              this.chartsPieSignedBrand = echarts.init(document.getElementById('chartsPieSignedBrand'))
              this.chartsPieSignedBrand.setOption(this.getOptionChartsPie())
              this.chartsPieSignedBrand.setOption({
                title: {
                  text: resData.signedBrandNums,
                  left: resData.signedBrandNums < 10 ? '25.5%' : '23.1%'
                },
                graphic: {
                  left: '24%',
                  style: {
                    text: '签约品牌',
                    fill: '#fff',
                    fontSize: 12
                  }
                },
                legend: {
                  data: resData.labels
                },
                series: [
                  {
                    data: seriesData
                  }
                ]
              })
              window.addEventListener('resize', () => {
                this.chartsPieSignedBrand.resize()
              })
              this.chartsPieSignedBrand.on('click', function (param) {
                that.goLink(3)
              })
            })
          } else {
            this.hasDataPieSignedBrand = false
            if (this.chartsPieSignedBrand) {
              this.chartsPieSignedBrand.dispose()
              this.chartsPieSignedBrand = null
            }
          }
        }
      }, rej => {
        this.hasDataPieSignedBrand = false
        console.log(rej)
      }).catch(err => {
        this.hasDataPieSignedBrand = false
        console.log(err)
      })
    },
    getDataFunnel () {
      const params = {
        begDate: '', // 跟进时间beg
        endDate: '',
        dateType: '', // 时间单位(日、月)
        floor: '', // 楼层
        followUserId: this.filtroFunnelMember, // 跟进人id 人员
        industry: this.filtroFunnelformat, // 业态
        projectId: this.filtroProject
      }
      const that = this
      this.axios.post(api.getStateChart, params).then(res => {
        if (res.data.code === 0 && res.data.data) {
          const resData = res.data.data
          const seriesData = resData.labels.map((item, index) => {
            const tempObj = {
              name: item,
              value: resData.values[index]
            }
            return tempObj
          })
          if (seriesData.length) {
            this.hasDataChartsFunnel = true
            this.$nextTick(() => {
              this.chartsFunnel = echarts.init(document.getElementById('chartsFunnel'))
              this.chartsFunnel.setOption(this.getOptionChartsFunnel())
              this.chartsFunnel.setOption({
                legend: {
                  data: resData.labels
                },
                series: [
                  {
                    data: seriesData
                  }
                ]
              })
              window.addEventListener('resize', () => {
                this.chartsFunnel.resize()
              })
              this.chartsFunnel.on('click', function (param) {
                that.goLink(4)
              })
            })
          } else {
            this.hasDataChartsFunnel = false
            if (this.chartsFunnel) {
              this.chartsFunnel.dispose()
              this.chartsFunnel = null
            }
          }
        }
      }, rej => {
        this.hasDataChartsFunnel = false
        console.log(rej)
      }).catch(err => {
        this.hasDataChartsFunnel = false
        console.log(err)
      })
    },
    getDataBarState () {
      const params = {
        followUserId: this.filtroFunnelMemberNum,
        industry: this.filtroStateformat,
        projectId: this.filtroProject
      }
      const that = this
      this.axios.post(api.getAiStateNumChat, params).then(res => {
        if (res.data.code === 0 && res.data.data) {
          const resData = res.data.data
          if (resData.labels.length !== 0) {
            this.hasDataChartsBarState = true
            this.$nextTick(() => {
              this.chartsBarState = echarts.init(document.getElementById('chartsBarState'))
              this.chartsBarState.setOption(this.getOptionChartsBar())
              this.chartsBarState.setOption(
                {
                  color: ['#22D6FE'],
                  yAxis: [
                    {
                      axisLabel: {
                        formatter: '{value}'
                      }
                    }
                  ],
                  xAxis: [
                    {
                      data: resData.labels
                    }
                  ],
                  series: [
                    {
                      type: 'bar',
                      barWidth: '18',
                      data: resData.values
                    }
                  ]
                }
              )
              window.addEventListener('resize', () => {
                this.chartsBarState.resize()
              })
              this.chartsBarState.on('click', function (param) {
                that.goLink(5)
              })
            })
          } else {
            this.hasDataChartsBarState = true
            if (this.chartsBarState) {
              this.chartsBarState.dispose()
              this.chartsBarState = null
            }
          }
        }
      }, rej => {
        this.hasDataChartsBarState = false
        console.log(rej)
      }).catch(err => {
        this.hasDataChartsBarState = false
        console.log(err)
      })
    },
    getDataPieMember (val) {
      if (val !== undefined) {
        this.filtroMember = val
      }
      const params = {
        projectId: this.filtroProject,
        type: this.filtroMember // 0-签约品牌数量 1-在跟进品牌数量
      }
      const that = this
      this.axios.post(api.getMemberTaskDateChart, params).then(res => {
        if (res.data.code === 0 && res.data.data) {
          const resData = res.data.data
          if (resData.labels.length > 0) {
            this.hasDataChartsPieMember = true
            let titleText = 0
            const seriesData = resData.labels.map((item, index) => {
              const tempObj = {
                name: item,
                value: resData.values[index]
              }
              titleText += resData.values[index]
              return tempObj
            })
            this.$nextTick(() => {
              this.chartsPieMember = echarts.init(document.getElementById('chartsPieMember'))
              this.chartsPieMember.setOption(this.getOptionChartsPie())
              window.addEventListener('resize', () => {
                this.chartsPieMember.resize()
              })
              this.chartsPieMember.on('click', function (param) {
                that.goLink(6)
              })
              this.chartsPieMember.setOption({
                title: {
                  text: titleText,
                  left: titleText < 10 ? '25.5%' : '23.1%'
                },
                graphic: {
                  left: '22.7%',
                  style: {
                    text: this.memberSelectList[this.filtroMember].label,
                    fill: '#fff',
                    fontSize: 12
                  }
                },
                tooltip: {
                  trigger: 'item',
                  formatter: '<span style="color:#FFA134;font-weight:bold;font-size:15px">{b}</span> <br/>' + this.memberSelectList[this.filtroMember].label + '：{c} '
                },
                legend: {
                  data: resData.labels
                },
                series: [
                  {
                    data: seriesData
                  }
                ]
              })
            })
          } else {
            this.hasDataChartsPieMember = false
            if (this.chartsPieMember) {
              this.chartsPieMember.dispose()
              this.chartsPieMember = null
            }
          }
        }
      }, rej => {
        this.hasDataChartsPieMember = false
        console.log(rej)
      }).catch(err => {
        this.hasDataChartsPieMember = false
        console.log(err)
      })
    },
    pieHandleData (resData) {
      let maxItem = {
        name: resData[0].name,
        percents: resData[0].percents
      }
      const dataArr = resData.map(item => {
        const tempObj = {
          name: item.name,
          value: item.counts,
          percents: item.percents
        }
        if (maxItem.percents < item.percents) {
          maxItem = item
        }
        return tempObj
      })
      return { dataArr, maxItem }
    },
    getDataRent (val) {
      if (val !== undefined) {
        this.filtroRent = val
      }
      const params = {
        floorOrIndustry: this.filtroRent, // 0-全部楼层，1-全部业态
        projectId: this.filtroProject
      }
      this.axios.post(api.getSignedAndTargetRentChat, params).then(res => {
        if (res.data.code === 0 && res.data.data) {
          const resData = res.data.data
          if (resData.labels.length > 0) {
            this.hasDataChartsBarRent = true
            const tempBool = (resData.labels.length > 5)
            const legendData = []
            const seriesData = resData.series.map(item => {
              const tempObj = {
                name: item.name,
                type: 'bar',
                barWidth: '18',
                barGap: '130%',
                data: item.data
              }
              legendData.push(item.name)
              return tempObj
            })
            const that = this
            this.$nextTick(() => {
              this.chartsBarRent = echarts.init(document.getElementById('chartsBarRent'))
              this.chartsBarRent.setOption(this.getOptionChartsBar())
              this.chartsBarRent.setOption(
                {
                  legend: {
                    data: legendData
                  },
                  yAxis: [
                    {
                      name: '万元',
                      axisLabel: {
                        formatter: '{value}'
                      }
                    }
                  ],
                  xAxis: [
                    {
                      data: resData.labels
                    }
                  ],
                  series: seriesData
                }
              )
              if (tempBool) {
                let tempObj = {}
                tempObj = {
                  xAxis: {
                    axisLabel: { interval: 0 }
                  },
                  grid: {
                    bottom: 35
                  },
                  legend: {
                    bottom: 10
                  },
                  dataZoom: [
                    {
                      type: 'slider',
                      show: true,
                      xAxisIndex: [0],
                      bottom: 3,
                      start: 0,
                      end: 45,
                      height: 6,
                      moveHandleSize: 2,
                      backgroundColor: 'none',
                      fillerColor: '#3c3e44',
                      textStyle: false,
                      borderColor: 'none',
                      handleStyle: {
                        color: '#3c3e44'
                      },
                      handleIcon: 'path://M512,512m-448,0a448,448,0,1,0,896,0a448,448,0,1,0,-896,0Z'
                    },
                    {
                      type: 'inside',
                      xAxisIndex: [0],
                      start: 1,
                      end: 45
                    }
                  ]
                }
                this.chartsBarRent.setOption(tempObj)
              }
              window.addEventListener('resize', () => {
                this.chartsBarRent.resize()
              })
              this.chartsBarRent.on('click', function (param) {
                that.goLink(2)
              })
            })
          } else {
            this.hasDataChartsBarRent = false
            if (this.chartsBarRent) {
              this.chartsBarRent.dispose()
              this.chartsBarRent = null
            }
          }
        }
      }, rej => {
        console.log(rej)
        this.hasDataChartsBarRent = false
      }).catch(err => {
        console.log(err)
        this.hasDataChartsBarRent = false
      })
    },
    getOptionChartsFunnel () {
      const option = {
        tooltip: {
          trigger: 'item',
          formatter: '{b} : {c}'
        },
        legend: {
          icon: 'square',
          bottom: '40%',
          right: '0',
          width: '195px',
          itemWidth: 10,
          itemHeight: 10,
          itemGap: 15,
          textStyle: {
            color: '#fff',
            padding: [0, 0, -3, 4]
          },
          data: []
        },
        color: ['#20D6FE', '#38B3EE', '#518BDA', '#5C61B6', '#885CB2'],
        series: [
          {
            name: '',
            type: 'funnel',
            top: 30,
            left: '1%',
            bottom: 10,
            width: '60%',
            sort: 'none',
            gap: 10,
            min: 0,
            max: 100,
            minSize: '26%',
            maxSize: '100%',
            label: {
              show: true,
              position: 'inside',
              formatter: '{c}'
            },
            itemStyle: {
              borderColor: 'transparent'
            },
            data: []
          }
        ]
      }
      return option
    },
    getOptionChartsPie () {
      const option = {
        title: {
          zlevel: 0,
          top: '33%',
          left: '23%',
          textStyle: {
            color: '#fff',
            fontSize: 35
          }
        },
        graphic: {
          type: 'text',
          zlevel: 0,
          top: '59%',
          left: '23%',
          style: {
            text: [],
            fill: '#fff',
            fontSize: 16
          }
        },
        tooltip: {
          trigger: 'item',
          formatter: '<span style="color:#FFA134;font-weight:bold;font-size:15px">{b}</span> <br/>招商数量' + '：{c} '
        },
        legend: {
          // orient: 'vertical',
          right: 0,
          top: '40%',
          width: '230px',
          data: [],
          color: '#fff',
          icon: 'circle',
          itemWidth: 10,
          itemHieght: 10,
          textStyle: {
            color: '#fff'
          }
        },
        series: [
          {
            name: '',
            type: 'pie',
            radius: ['60%', '80%'],
            center: ['28.6%', '50%'],
            avoidLabelOverlap: false,
            zlevel: 1,
            hoverOffset: 11,
            label: { //  饼图图形上的文本标签
              normal: { // normal 是图形在默认状态下的样式
                show: false,
                position: 'center',
                fontSize: 16,
                fontWeight: 'bold'
                // formatter: '{c}' // {b}:数据名； {c}：数据值； {d}：百分比，可以自定义显示内容，
              }
            },
            itemStyle: {
              normal: {
                shadowBlur: 50,
                shadowColor: '#444'
              }
            },
            labelLine: {
              normal: {
                show: false
              }
            },
            data: []
          }
        ],
        color: ['#34A6F5', '#237FC0', '#4451B1', '#00D7D5', '#0FC967', '#8ED409', '#FFCC00', '#6B6E7E']
      }
      return option
    },
    getOptionChartsBar () {
      const option = {
        color: ['#0FC967', '#22D6FE'],
        tooltip: {
          trigger: 'axis',
          axisPointer: { // 坐标轴指示器，坐标轴触发有效
            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
          }
        },
        legend: {
          type: 'plain',
          bottom: 0,
          itemGap: 15,
          itemWidth: 10,
          itemHeight: 10,
          textStyle: {
            color: '#fff',
            fontSize: '10'
          }
        },
        grid: {
          left: '3%',
          right: '3%',
          bottom: '10%',
          top: '14%',
          show: false,
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            data: [],
            axisTick: {
              show: false,
              alignWithLabel: true
            },
            axisLine: {
              show: false,
              lineStyle: {
                color: 'rgba(255,255,255,.5',
                fontSize: '10'
              }
            },
            splitLine: {
              show: false,
              lineStyle: {
                color: ['rgba(226,226,226,.1'],
                width: 1,
                type: 'solid'
              }
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            nameTextStyle: {
              color: '#fff'
            },
            minInterval: 1,
            axisTick: {
              show: false,
              alignWithLabel: true
            },
            axisLabel: {
              textStyle: {
                color: 'rgba(255,255,255,.5',
                fontSize: '10'
              }
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: ['rgba(226,226,226,.1'],
                width: 1,
                type: 'solid'
              }
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: ['rgba(226,226,226,.1'],
                width: 1,
                type: 'solid'
              }
            }
          }
        ],
        emphasis: {
          label: {
            show: true,
            formatter: '{c}',
            position: 'top',
            color: '#fff'
          }
        },
        series: [
          {
            name: '',
            type: 'bar',
            data: []
          }
        ]
      }
      return option
    }
  }
}
</script>

<style lang="stylus" scoped>
.details-no-data
  margin-top 20px
.biModules
  width 97%
  margin 27px auto
  h1
    font-size 28px
    color #ffa132
    margin-bottom 30px
    font-weight 700
  .boxHeight0
    height 356px !important
  .commonBox
    color #fff
    height 260px
    background #23262D
    box-shadow inset 0px 0px 10px 0px rgba(176, 191, 226, 0.15)
    border-radius 2px
    .commonText
      color #70747D
      font-size 14px
    .thirdUl
      margin-top 14px
      padding-bottom 25px
      li
        display inline-block
      li:first-child
        width 41%
    .thirdUl1
      margin-top 14px
      white-space nowrap
      .commonText
        font-size 12px !important
      li
        margin-right 25px
        display inline-block
        text-align center
      li:last-child
        margin 0
    .halfUl
      white-space nowrap
      margin-top 24px
      li
        display inline-block
      li:first-child
        width 50%
    .numPanel
      display inline-block
      width 80%
      height 58px
      font-weight bold
      text-align center
      line-height 59px
      background url('~@/assets/images/numBg.png') no-repeat
      border-radius 4px
      font-size 40px
      &:after
        content '%'
        font-size 16px
    .commonNum
      font-weight bold
      font-family SF UI Display
      font-size 26px
      span
        font-weight 500
        font-size 12px
        display inline-block
      .fs10
        position relative
        right 8px
        transform scale(0.83)
    .botBorderDashed
      border-bottom 1px dashed rgba(255, 255, 255, 0.1)
    #chartsBarRent
      margin-top 10px
    #chartsPieSignedBrand, #chartsPieMember
      box-sizing border-box
      margin-top 25px
    #chartsPieBrandPrediction
      margin-left -46px
  .tbsCommon
    margin 10px 10px 0 0
    span
      display inline-block
      margin 0 10px
      cursor pointer
      font-size 12px
    span.activeSpan
      color #FFA134
  .tbsSpan
    span
      display inline-block
      width 63px
      height 22px
      line-height 22px
      font-size 12px
      cursor pointer
      border 1px solid rgba(255, 255, 255, 0.1)
    span:first-child
      border-radius 2px 0px 0px 2px
    span:last-child
      border-radius 0px 2px 2px 0px
      margin-left -1px
    span.activeSpan
      background rgba(255, 255, 255, 0.2)
  .chartsBox0
    width 98%
    height 291px
  .chartsBox1
    width 98%
    height 170px
  .chartsBox
    width 98%
    height 210px
  .ta-c
    text-align center
  .ta-l
    text-align left
  .ta-r
    text-align right
  .wrap0
    margin 24px 64px 34px 64px
  .wrap1
    margin 24px 43px 34px 43px
  .box0
    width 30%
    margin 0 12px 12px 0
    overflow hidden
  .box1
    width 69%
  .box2
    width 40%
    margin 0 12px 12px 0
    overflow hidden
  .box3
    width 28.35%
  .mt80
    margin-top 80px
  .mt29
    margin-top 29px
.biModules>>>
  .commonSelect
    margin-right 13px
    .el-select
      width 85px
      font-size 12px
      background none
      .el-input__inner
        background none
        border none
    .el-select-dropdown
      background none !important
      border none !important
  .w-input-sty
    .el-input__inner
      width 100%
      padding 0 15px
    .el-input__suffix
      right 0
  .width-100
    width 100px !important
@media screen and (max-width 1500px)
  .w-title-gradients
    width 200px
</style>
